import React, { useContext } from 'react';
import { Modal as ShopifyModal } from '@shopify/polaris';
import { PlanContext } from '../providers/UpgradePlanContext';
import { useRouter } from 'next/router';

export const PlanModal = () => {
  const { plan, hide } = useContext(PlanContext);
  const router = useRouter();

  return (
    <ShopifyModal
      open={plan.visible}
      onClose={hide}
      title={plan.title}
      primaryAction={{
        content: 'Upgrade plan',
        onAction: () => {
          hide();
          router.push('/plans');
        },
      }}
      sectioned
    >
      <div dangerouslySetInnerHTML={{ __html: plan.content }} />
    </ShopifyModal>
  );
};

export default PlanModal;
