import { ShopInterface } from '../models/shop';
export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;

export const boot = (shop: ShopInterface) => {
  window.Intercom('boot', {
    app_id: INTERCOM_APP_ID,
    email: shop.customerEmail,
    name: shop.ownerName,
    user_id: shop._id,
    user_hash: shop.userHash,
    plan: shop.plan.name,
    company: {
      id: shop.shopID,
      name: shop.shopName || shop.name.replace('myshopify.com', ''),
      plan: shop.plan.name,
      website: shop.name,
      created_at: shop.createdAt,
    },
    created_at: shop.createdAt,
  });
};
