import React, { useContext } from 'react';
import { Modal as ShopifyModal, TextContainer } from '@shopify/polaris';
import { ModalContext } from '../providers/ModalContext';

export const Modal = () => {
  const { modal, hide } = useContext(ModalContext);

  const messageMarkup = modal.content && (
    <TextContainer>
      {typeof modal.content === 'string' ? <p>{modal.content}</p> : modal.content}
    </TextContainer>
  );

  let primaryAction = null;
  if (modal.primaryAction) {
    primaryAction = {
      loading: modal.loading,
      onAction: hide,
      ...modal.primaryAction,
    };
  }

  const secondaryActions = [];
  if (modal.secondaryAction) {
    secondaryActions.push({
      onAction: hide,
      ...modal.secondaryAction,
    });
  }

  return (
    <ShopifyModal
      open={modal.visible}
      onClose={hide}
      title={modal.title}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
    >
      <ShopifyModal.Section>{messageMarkup}</ShopifyModal.Section>
    </ShopifyModal>
  );
};

export default Modal;
