import * as React from 'react';
import { useRouter } from 'next/router';
import { LinkLikeComponentProps } from '@shopify/polaris/dist/types/latest/src/utilities/link/types';

const ShopifyLinkRouter: React.FC<LinkLikeComponentProps> = (props) => {
  const router = useRouter();
  // eslint-disable-next-line no-redeclare,@typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const { children, url, external, ...otherProps } = props;
  const handleOnClick = (e) => {
    e.preventDefault();
    if (props.onClick) {
      props.onClick(e);
    }

    if (external) {
      window.open(url, '_blank');
    } else {
      router.push(url);
    }
  };
  return (
    <a href="#" {...otherProps} onClick={handleOnClick}>
      {children}
    </a>
  );
};

export default ShopifyLinkRouter;
