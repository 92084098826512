import * as React from 'react';
import { DisplayText, Spinner, TextContainer } from '@shopify/polaris';

interface SpinnerProps {
  src: string;
}

const IconSpinner: React.FC<SpinnerProps> = ({ src }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div
        style={{
          borderRadius: '25px',
          width: 100,
          height: 100,
          marginBottom: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'linear-gradient(45deg, rgba(101,84,255,1) 0%, rgba(113,139,255,1) 100%)',
        }}
      >
        <img
          style={{ position: 'absolute', width: 130, borderRadius: 4 }}
          src={src}
          className="artwork"
        />
      </div>
      <TextContainer>
        <DisplayText size="medium">Getting everything ready..</DisplayText>
      </TextContainer>
      <div style={{ marginTop: 5 }}>
        <Spinner size="small" />
      </div>
    </div>
  );
};

export default IconSpinner;
