import * as React from 'react';
import { Navigation, SkeletonBodyText } from '@shopify/polaris';
import {
  HomeMajor,
  CategoriesMajor,
  SettingsMajor,
  PlayMajor,
  PlusMinor,
  ListMajor,
  FolderUpMajor,
  LegalMajor,
} from '@shopify/polaris-icons';
import { useContext, useEffect } from 'react';
import { SheetContext } from '../providers/SheetContext';
import { ModalContext } from '../providers/ModalContext';
import UploadMediaModal from './upload/UploadMediaModal';
import { PlanContext } from '../providers/UpgradePlanContext';
import { useRouter } from 'next/router';

interface AppNavigationProps {
  location: string;
}

const AppNavigation: React.FC<AppNavigationProps> = (props) => {
  const { show: showSheet } = useContext(SheetContext);
  const { show } = useContext(ModalContext);
  const { show: showPlan, plan } = useContext(PlanContext);

  useEffect(() => {}, []);
  const router = useRouter();
  const openAlbums = () => {
    if (!plan.currentPlan.albums.isEnabled) {
      return showPlan({
        content: `You're now on <b>${plan.currentPlan.name}</b> plan. This plan does not include albums. Please upgrade to continue.`,
      });
    }
    router.push('/albums/list');
  };

  const openTracklists = () => {
    if (!plan.currentPlan.tracklists.isEnabled) {
      return showPlan({
        content: `You're now on <b>${plan.currentPlan.name}</b> plan. This plan does not include tracklists. Please upgrade to continue.`,
      });
    }
    router.push('/tracklists/list');
  };
  const openTracks = () => {
    if (!plan.currentPlan.tracks.isEnabled) {
      return showPlan({
        content: `You're now on <b>${plan.currentPlan.name}</b> plan. This plan does not include tracks. Please upgrade to continue.`,
      });
    }
    router.push('/tracks');
  };
  const openLicenses = () => {
    if (!plan.currentPlan.licenses.isEnabled) {
      return showPlan({
        content: `You're now on <b>${plan.currentPlan.name}</b> plan. This plan does not include licenses. Please upgrade to continue.`,
      });
    }
    router.push('/licenses');
  };

  const loadedState = plan.currentPlan && (
    <Navigation location={props.location}>
      <Navigation.Section
        title={'Overview'}
        items={[
          {
            url: '/dashboard',
            label: 'Dashboard',
            icon: HomeMajor,
          },
        ]}
      />
      <Navigation.Section
        title={'Music & Media'}
        items={[
          {
            onClick: openTracks,
            label: 'Tracks',
            icon: PlayMajor,
          },
          {
            onClick: openTracklists,
            label: 'Tracklists',
            icon: ListMajor,
          },
          {
            onClick: openAlbums,
            label: 'Albums',
            icon: CategoriesMajor,
          },
        ]}
      />
      <Navigation.Section
        title={'Uploads'}
        separator
        action={{
          accessibilityLabel: 'Upload new media',
          icon: PlusMinor,
          onClick: () => {
            show({
              content: <UploadMediaModal visible={true} />,
            });
          },
        }}
        items={[
          {
            onClick: () => {
              showSheet({
                title: 'Download media file',
              });
            },
            label: 'My uploads',
            icon: FolderUpMajor,
          },
        ]}
      />
      <Navigation.Section
        title={'Settings'}
        separator
        items={[
          {
            onClick: openLicenses,
            label: 'Licenses & Contracts',
            icon: LegalMajor,
          },
          {
            url: '/settings',
            label: 'Settings',
            icon: SettingsMajor,
          },
        ]}
      />
    </Navigation>
  );

  const skeletonState = !plan.currentPlan && (
    <Navigation location={props.location}>
      <Navigation.Section title={'Overview'} items={[]} />
      <SkeletonBodyText />
      <Navigation.Section title={'Music & Media'} items={[]} />
      <SkeletonBodyText />

      <Navigation.Section title={'Uploads'} separator items={[]} />
      <SkeletonBodyText />
      <Navigation.Section title={'Settings'} separator items={[]} />
      <SkeletonBodyText />
    </Navigation>
  );
  return (
    <React.Fragment>
      {loadedState}
      {skeletonState}
    </React.Fragment>
  );
};

export default AppNavigation;
