import React, { useContext, useEffect, useState } from 'react';
import {
  Sheet as ShopifySheet,
  Button,
  Heading,
  Subheading,
  Scrollable,
  Stack,
  Spinner,
} from '@shopify/polaris';
import { MobileCancelMajor, PlusMinor } from '@shopify/polaris-icons';
import useSWR from 'swr';
import { SheetContext } from '../providers/SheetContext';
import { ModalContext } from '../providers/ModalContext';
import UploadMediaModal from './upload/UploadMediaModal';
import fetcher from '../lib/fetcher';
import { File } from '../models/file';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CancelSmallMinor } from '@shopify/polaris-icons';
import { ToastContext } from '../providers/ToastContext';
import { getIconByMediaType } from '../utils/media';

export const Sheet = () => {
  const { sheet, hide, clearUploads } = useContext(SheetContext);
  const { show } = useContext(ModalContext);
  const { show: showToast } = useContext(ToastContext);

  const initData = {
    uploading: [],
    audio: [],
    video: [],
    image: [],
    application: [],
  };
  const [groupedFiles, setGroupedFiles] = useState(initData);

  const { data: files, mutate } = useSWR<File[]>(
    `${process.env.APP_URL}/files?limit=500`,
    fetcher,
    {
      refreshInterval: sheet.visible ? 3000 : 0,
    },
  );

  useEffect(() => {
    if (files) {
      setGroupedFiles(groupFilesByType(files));
      clearUploads();
    }
  }, [files]);

  const removeFile = async (file: File) => {
    await mutate(
      files.map((curr) => {
        if (curr._id === file._id) {
          curr.status = 'deleting';
        }
        return curr;
      }),
    );
    setGroupedFiles(groupFilesByType(files));

    const request = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('tuneboomAccessToken')}`,
      },
    };

    const result = await fetch(`${process.env.APP_URL}/files/${file._id}`, request);

    if (result.status !== 200) {
      return showToast({ message: 'Failed to delete file', isError: true });
    }
    await mutate(files.filter((currFile) => currFile._id !== file._id));
  };

  const groupFilesByType = (files: File[]) => {
    return files.reduce((acc, curr) => {
      if (curr.status === 'pending' || curr.status === 'processing') {
        if (!acc['uploading']) {
          acc['uploading'] = [curr];
          return acc;
        }
        acc['uploading'].push(curr);
      } else {
        if (!acc[curr.type]) {
          acc[curr.type] = [curr];
          return acc;
        }
        acc[curr.type].push(curr);
      }
      return acc;
    }, initData);
  };

  const fileMarkup = (file: File) => {
    let title = `${file.metadata.name || '?'}`;
    if (file.status === 'aborted') title = `${title} (cancelled)`;

    return (
      <div
        style={{
          paddingTop: 15.6,
          paddingBottom: 10,
          borderBottom: 1,
        }}
      >
        <Stack>
          <Stack.Item>
            <div style={{ width: 20 }}>
              <FontAwesomeIcon icon={getIconByMediaType(file.metadata.type)} size={'lg'} />
            </div>
          </Stack.Item>
          <Stack.Item fill>
            <p
              style={{
                width: 250,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {title}
            </p>
          </Stack.Item>
          <Stack.Item>
            {(file.status === 'pending' || file.status === 'deleting') && (
              <Spinner size="small" color="inkLightest" />
            )}
            {file.status !== 'pending' && file.status !== 'deleting' && (
              <Button plain icon={CancelSmallMinor} onClick={() => removeFile(file)} />
            )}
          </Stack.Item>
        </Stack>
      </div>
    );
  };
  const uploadingMarkup = (files: File[]) => {
    if (files.length === 0) {
      return null;
    }
    const items = files.map((file) => fileMarkup(file));
    return (
      <div
        style={{
          alignItems: 'center',
          borderBottom: '1px solid #DFE3E8',
          justifyContent: 'space-between',
          padding: '1.6rem',
          width: '100%',
        }}
      >
        <Subheading>Processing</Subheading>
        {items}
      </div>
    );
  };

  const sectionMarkup = (title: string, files: File[]) => {
    if (files.length === 0) {
      return null;
    }
    const items = files.map((file) => fileMarkup(file));
    return (
      <div
        style={{
          alignItems: 'center',
          borderBottom: '1px solid #DFE3E8',
          justifyContent: 'space-between',
          padding: '1.6rem',
          width: '100%',
        }}
      >
        <Subheading>{title}</Subheading>
        {items}
      </div>
    );
  };

  return (
    <ShopifySheet open={sheet.visible} onClose={hide}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1.6rem',
            width: '100%',
          }}
        >
          <Heading>My uploads</Heading>
          <Button accessibilityLabel="Cancel" icon={MobileCancelMajor} onClick={hide} plain />
        </div>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1.6rem',
            width: '100%',
          }}
        >
          <Button
            primary
            icon={PlusMinor}
            fullWidth
            onClick={() => {
              hide();
              show({
                content: <UploadMediaModal visible={true} />,
              });
            }}
          >
            Upload media
          </Button>
        </div>
        <Scrollable style={{ height: '100%' }}>
          {uploadingMarkup([...groupedFiles.uploading, ...sheet.newUploads])}
          {sectionMarkup('mp3 files', groupedFiles.audio)}
          {sectionMarkup('zip/rar files', groupedFiles.application)}
          {sectionMarkup('artwork', groupedFiles.image)}
        </Scrollable>

        <div
          style={{
            alignItems: 'center',
            borderTop: '1px solid #DFE3E8',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1.6rem',
            width: '100%',
          }}
        >
          <Button onClick={hide}>Cancel</Button>
        </div>
      </div>
    </ShopifySheet>
  );
};

export default Sheet;
