import React, { useContext } from 'react';
import { ToastContext } from '../providers/ToastContext';
import { Toast as Toastie } from '@shopify/polaris';

export const Toast = () => {
  const { toast, hide } = useContext(ToastContext);

  const toastMarkup = toast.visible ? (
    <Toastie content={toast.message} error={toast.isError} onDismiss={hide} />
  ) : null;

  return <React.Fragment>{toastMarkup}</React.Fragment>;
};

export default Toast;
