import React, { useCallback, useEffect, useState } from 'react';
import Head from 'next/head';
import { AppType } from 'next/dist/next-server/lib/utils';
import { AppProvider, ContextualSaveBar, Frame } from '@shopify/polaris';
import createApp, { AppConfigV2 } from '@shopify/app-bridge';
import translations from '@shopify/polaris/locales/en.json';
import ShopifyLinkRouter from '../components/shopify-link-router';
import { useRouter } from 'next/router';
import '@shopify/polaris/dist/styles.css';
import 'react-dropzone-uploader/dist/styles.css';
import 'static/assets/app.css';
import 'suneditor/dist/css/suneditor.min.css';
import * as Sentry from '@sentry/node';
import { ToastProvider } from '../providers/ToastContext';
import { ModalProvider } from '../providers/ModalContext';
import * as gtag from '../utils/gtag';
import Toast from '../components/Toast';
import Modal from '../components/Modal';
import AppNavigation from '../components/AppNavigation';
import { SheetProvider } from '../providers/SheetContext';
import Sheet from '../components/Sheet';
import { PlanProvider } from '../providers/UpgradePlanContext';
import PlanModal from '../components/UpgradePlan';
import useUser from '../hooks/useUser';
import { boot } from '../utils/intercom';
import IconSpinner from '../components/IconSpinner';
import { useLocalStorage } from 'react-use';

const ENTRY_PAGES = ['/shopify/callback', '/auth/impersonate'];

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    debug: true,
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const lightTopBarColors = {
  background: '#FFFFFF',
  backgroundLighter: '#F4F6F8',
  backgroundDarker: '#DFE3E8',
  boxShadow: 'none',
  border: '#C4CDD5',
  color: '#212B36',
};

const theme = {
  colors: {
    topBar: lightTopBarColors,
    primary: '#6554ff',
    interactive: '#6554FF',
    highlight: '#6554ff',
  },
  logo: {
    background: '#000000',
    width: 124,
    topBarSource: 'static/images/tuneboom-logo-dark.svg',
    accessibilityLabel: 'Tuneboom Music',
  },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const MyApp: AppType = ({ Component, pageProps, err }) => {
  const { user, loggedOut, updateRequired, mutate } = useUser({ redirectTo: '/login' });
  const router = useRouter();
  const [noRedirect] = useLocalStorage('noRedirect', false);
  const [childLoading, setLoading] = useState(false);
  const handleSetLoading = (bool: boolean) => {
    setSubmit(bool);
    setLoading(bool);
  };
  const [submit, setSubmit] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [primaryDisable] = useState(false);
  const [saveEnabled, setEnabled] = useState(false);
  const [updateInit, setUpdateInit] = useState(false);
  const [appClient, setAppClient] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setIsUserMenuOpen] = useState(false);
  const [hideLayout, setHideLayout] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setIsSearchActive] = useState(false);
  const [, setSearchValue] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useCallback(() => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen), []);
  const isDebugging = process.env.TUNEBOOM_DEBUG === 'on' || noRedirect;

  const fetchUpdate = async () => {
    setUpdateInit(true);
    const request = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('tuneboomAccessToken')}`,
      },
    };
    await fetch(`${process.env.APP_URL}/users/upgrade`, request);
    await mutate();
  };

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      if (router.pathname === '/login' || router.pathname === '/signup') {
        setHideLayout(true);
      } else {
        setHideLayout(false);
      }
      gtag.pageview(url);
    };

    if (user && !appClient) {
      boot(user);
      if (user.host) {
        const appConfig: AppConfigV2 = {
          apiKey: process.env.SHOPIFY_API_KEY,
          host: user.host,
        };
        setAppClient(createApp(appConfig));
      }
    }

    if (router.pathname === '/login' || router.pathname === '/signup') {
      setHideLayout(true);
    } else {
      setHideLayout(false);
    }

    if (updateRequired && !updateInit && user.channel === 'SHOPIFY') {
      fetchUpdate();
    }

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [
    router.pathname,
    router.events,
    user,
    updateRequired,
    loggedOut,
    setAppClient,
    appClient,
    hideLayout,
  ]);

  const handleSetEnabled = (bool: boolean) => {
    setEnabled(bool);
    if (discard && bool) setDiscard(false);
    if (submit && bool) setSubmit(false);
  };
  useCallback((value) => {
    setSearchValue(value);
    setIsSearchActive(value.length > 0);
  }, []);
  const isInFrame = typeof window !== 'undefined' && window?.top !== window?.self;
  const navigation = <AppNavigation location={router.pathname} />;
  const loadingScreen = updateRequired && <IconSpinner src={'/static/images/white_logomark.svg'} />;

  const showComponent = (ENTRY_PAGES.includes(router.pathname) ||
    (isDebugging && !updateRequired) ||
    (isInFrame && !updateRequired)) && (
    <ToastProvider>
      <ModalProvider>
        <SheetProvider>
          <PlanProvider>
            <Frame navigation={hideLayout || !user ? null : navigation}>
              <RenderSaveBar
                enabled={saveEnabled}
                loading={childLoading}
                setDiscard={setDiscard}
                setSubmit={setSubmit}
                setLoading={setLoading}
                primaryDisable={primaryDisable}
              />
              <Toast />
              <Modal />
              <Sheet />
              <PlanModal />
              <Component
                {...pageProps}
                {...(user ? { appBridge: appClient } : {})}
                barEnabled={handleSetEnabled}
                setLoading={handleSetLoading}
                onSubmit={submit}
                onDiscard={discard}
                primaryDisable={primaryDisable}
              />
            </Frame>
          </PlanProvider>
        </SheetProvider>
      </ModalProvider>
    </ToastProvider>
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return (
    <React.Fragment>
      <Head>
        <title>Tuneboom - Shopify Music Distribution</title>
        <link rel="shortcut icon" href="/static/favicon.png" />
        <meta charSet="utf-8" />
      </Head>
      <AppProvider
        linkComponent={ShopifyLinkRouter}
        i18n={translations}
        theme={theme}
        features={{ newDesignLanguage: true }}
      >
        {loadingScreen}
        {showComponent}
      </AppProvider>
    </React.Fragment>
  );
};

function RenderSaveBar({ setSubmit, setLoading, primaryDisable, setDiscard, loading, enabled }) {
  if (!enabled) return null;

  return (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        onAction: () => {
          setSubmit(true);
          setLoading(true);
        },
        loading: loading,
        disabled: primaryDisable,
      }}
      discardAction={{
        onAction: () => {
          setDiscard(true);
        },
      }}
    />
  );
}
export default MyApp;
